import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acClearRecentProducts, acGetRecentProducts } from '@actions/acCommon';
import Section from '@components/common/Section';
import RecentlyViewedWrapper from '@components/pages/home/recently-viewed/RecentlyViewedWrapper';
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect';
import { useTranslation } from '@hooks/useTranslation';
import { IHomePageComponentDataObject } from '@interfaces/home';
import { TThunkDispatch } from '@interfaces/index';
import { IStore } from '@interfaces/store';

export interface IRecentlyViewedComponentProps {
  component?: IHomePageComponentDataObject;
  className?: string;
  hideOutOfStock?: boolean;
  updateKey?: string | number;
}

const RecentlyViewedComponent: React.FunctionComponent<IRecentlyViewedComponentProps> = ({ className, hideOutOfStock, updateKey }) => {
  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const { t } = useTranslation();

  const recentProducts = useSelector((state: IStore) => state.common.recentProducts);
  const loaded = useSelector((state: IStore) => state.common.loaded.recentProducts);
  const onGetRecentProducts = useCallback(() => dispatch(acGetRecentProducts()), [dispatch]);
  const onClearRecentProducts = useCallback<()=>Promise<any>>(() => dispatch(acClearRecentProducts()), [dispatch]);

  useEffect(() => {
    if (!loaded) {
      onGetRecentProducts();
    }
  }, []);

  useDidUpdateEffect(() => {
    onGetRecentProducts();
  }, [updateKey]);

  return (
      <Section className={className} title={t('common.recently_viewed')}
               showGenderCategories={false}
               showViewAllButton={false}
               showClearAllButton={!!recentProducts?.length}
               onClearAllButtonClick={onClearRecentProducts}
      >
        <RecentlyViewedWrapper hideOutOfStock={hideOutOfStock} />
      </Section>
  );
};

export default RecentlyViewedComponent;
